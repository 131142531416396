import axios from 'axios'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  VERIFY_QR_REQ,
  VERIFY_QR_SUCCESS,
  VERIFY_QR_FAILED,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_ENGR_REQUEST,
  CREATE_ENGR_SUCCESS,
  CREATE_ENGR_FAILED,
  USER_EMPLOYEELOGIN_REQUEST,
  USER_EMPLOYEELOGIN_SUCCESS,
  USER_EMPLOYEELOGIN_FAILED,
  GET_ENGRPROFILE_REQUEST,
  GET_ENGRPROFILE_SUCCESS,
  GET_ENGRPROFILE_FAILED,
  GET_EMAIL_REQUEST,
  GET_EMAIL_SUCCESS,
  GET_EMAIL_FAILED,
  GET_RESET_PASSWORD_REQUEST,
  GET_RESET_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_FAILED,
  PUT_RESET_PASSWORD_REQUEST,
  PUT_RESET_PASSWORD_SUCCESS,
  PUT_RESET_PASSWORD_FAILED,
  GET_VERIFYPERMIT_REQUEST,
  GET_VERIFYPERMIT_SUCCESS,
  GET_VERIFYPERMIT_FAILED,
  CREATE_PROFINFO_REQ,
  CREATE_PROFINFO_SUCCESS,
  CREATE_PROFINFO_FAILED,
  DELETE_PROFINFO_REQ,
  DELETE_PROFINFO_SUCCESS,
  DELETE_PROFINFO_FAILED,
  USER_LOGOUT_FAILED,
  USER_RPTLOGIN_REQUEST,
  USER_RPTLOGIN_SUCCESS,
  USER_RPTLOGIN_FAILED,
} from '../constants/userConstants'
import Cookie from 'js-cookie'
axios.defaults.withCredentials = true

export const Login = (payload) => {
  return async (dispatch) => {
    try {
      axios.defaults.withCredentials = true
      dispatch({ type: USER_LOGIN_REQUEST })

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        payload,
        { withCredentials: true }
      )

      // const { data } = await axios({
      //   method: 'POST',
      //   url: `${process.env.REACT_APP_API_URL}/users/login`,
      //   data: payload,
      //   withCredentials: true,
      // })

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: {
          id: data.id,
          username: data.username,
          roles: data.roles,
          subroles: data.subroles,
        },
      })
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          id: data.id,
          username: data.username,
          roles: data.roles,
          subroles: data.subroles,
        })
      )
      Cookie.set('userInfo', data.token, {
        expires: 1,
        secure: false,
      })
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAILED,
        payload: error.response && error.response.data.message,
      })
    }
  }
}

export const verifiedQr = (payload) => {
  return async (dispatch) => {
    try {
      axios.defaults.withCredentials = true
      dispatch({ type: VERIFY_QR_REQ })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/verified-permit-qr/?id=${payload}`,
        payload,
        { withCredentials: true }
      )

      dispatch({
        type: VERIFY_QR_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: VERIFY_QR_FAILED,
        payload: error.response && error.response.data.message,
      })
    }
  }
}

export const Logout = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_LOGOUT_REQUEST })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/logout`
      )

      localStorage.removeItem('userInfo')
      Cookie.remove('userInfo')
      Cookie.remove('rptAccess')
      dispatch({ type: USER_LOGOUT_SUCCESS })
    } catch {
      dispatch({ type: USER_LOGOUT_FAILED })
    }
  }
}

export const retrieveEmail = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_EMAIL_REQUEST })

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/forgot-password`,
        payload
      )

      dispatch({
        type: GET_EMAIL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_EMAIL_FAILED,
        payload: error.response && error.response.data.message,
      })
    }
  }
}

export const retrieveResetPassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_RESET_PASSWORD_REQUEST })

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/reset-password/${payload.id}/${payload.token}`,
        payload
      )

      dispatch({
        type: GET_RESET_PASSWORD_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_RESET_PASSWORD_FAILED,
        payload: 'Error',
      })
    }
  }
}

export const userResetPassword = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PUT_RESET_PASSWORD_REQUEST })

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/reset-password/${payload.id}/${payload.token}`,
        payload
      )

      dispatch({
        type: PUT_RESET_PASSWORD_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PUT_RESET_PASSWORD_FAILED,
        payload: 'Error',
      })
    }
  }
}

export const employeeLogin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_EMPLOYEELOGIN_REQUEST })

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/employee-login`,
        payload
      )

      dispatch({
        type: USER_EMPLOYEELOGIN_SUCCESS,
        payload: {
          id: data.id,
          username: data.username,
          roles: data.roles,
          subroles: data.subroles,
        },
      })

      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          id: data.id,
          username: data.username,
          roles: data.roles,
          subroles: data.subroles,
        })
      )

      Cookie.set('userInfo', data.token, {
        expires: 1,
        secure: false,
      })
    } catch (error) {
      dispatch({
        type: USER_EMPLOYEELOGIN_FAILED,
        payload: 'Invalid username or password',
      })
    }
  }
}

export const verifyPermit = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VERIFYPERMIT_REQUEST })

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/verify-permit/${payload.permit}`,
        payload
      )

      dispatch({
        type: GET_VERIFYPERMIT_SUCCESS,
        payload: data.result,
      })
    } catch (error) {
      dispatch({
        type: GET_VERIFYPERMIT_FAILED,
        payload: 'Not Found',
      })
    }
  }
}

export const createProInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_PROFINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/create-prof-info`,
        payload,
        config
      )

      dispatch({
        type: CREATE_PROFINFO_SUCCESS,
        payload: data.result,
      })
    } catch (error) {
      dispatch({
        type: CREATE_PROFINFO_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const deleteProInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PROFINFO_REQ })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/users/delete-prof-info/${payload.id}`,
        config
      )

      dispatch({
        type: DELETE_PROFINFO_SUCCESS,
        payload: data.result,
      })
    } catch (error) {
      dispatch({
        type: DELETE_PROFINFO_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

export const rptLogin = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_RPTLOGIN_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/RptApi?extensions=/token`
      )

      dispatch({
        type: USER_RPTLOGIN_SUCCESS,
        payload: 'success',
      })

      Cookie.set('rptAccess', data.access_token, {
        expires: 1,
        sameSite: 'strict',
        secure: true,
      })
    } catch (error) {
      dispatch({
        type: USER_RPTLOGIN_FAILED,
        payload: 'Transaction Failed',
      })
    }
  }
}

// Create user
export const createUser = (payload) => {
  return async (dispatch) => {
    // let info
    try {
      dispatch({ type: CREATE_USER_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      Object.values(payload.images).forEach((element) => {
        formData.append('images', element)
      })
      formData.append('FirstName', payload.FirstName)
      formData.append('MiddleName', payload.MiddleName)
      formData.append('LastName', payload.LastName)
      formData.append('CivilStatus', payload.CivilStatus)
      formData.append('Gender', payload.Gender)
      formData.append('MobileNo', payload.MobileNo)
      formData.append('EmailAddress', payload.EmailAddress)
      formData.append('DOB', payload.DOB)
      formData.append('username', payload.username)
      formData.append('password', payload.password)
      formData.append('confirmPassword', payload.confirmPassword)
      formData.append('TermsNCondition', payload.TermsNCondition)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/create-user`,
        formData,
        config
      )

      // console.log(data)

      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: CREATE_USER_FAILED,
        payload: err.response && err.response.data.message,
      })
    }
  }
}

// Create Engr
export const createEngr = (payload) => {
  return async (dispatch) => {
    // let info
    try {
      dispatch({ type: CREATE_ENGR_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${Cookie.get('userInfo')}`,
        },
      }

      const formData = new FormData()
      Object.values(payload.images).forEach((element) => {
        formData.append('images', element)
      })
      formData.append('FirstName', payload.FirstName)
      formData.append('MiddleName', payload.MiddleName)
      formData.append('LastName', payload.LastName)
      formData.append('CivilStatus', payload.CivilStatus)
      formData.append('Gender', payload.Gender)
      formData.append('MobileNo', payload.MobileNo)
      formData.append('EmailAddress', payload.EmailAddress)
      formData.append('DOB', payload.DOB)
      formData.append('username', payload.username)
      formData.append('password', payload.password)
      formData.append('confirmPassword', payload.confirmPassword)
      formData.append('TermsNCondition', payload.TermsNCondition)
      formData.append('profession', payload.profession)
      formData.append('prcRegNo', payload.prcRegNo)
      formData.append('validity', payload.validity)
      formData.append('ptrNo', payload.ptrNo)
      formData.append('dateIssued', payload.dateIssued)

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/create-engineer`,
        formData,
        config
      )

      dispatch({
        type: CREATE_ENGR_SUCCESS,
        payload: data.message,
      })
    } catch (err) {
      dispatch({
        type: CREATE_ENGR_FAILED,
        payload: err.response && err.response.data.message,
      })
    }
  }
}
